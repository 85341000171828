$primary-color: #2C66E3;
$secondary-color: #787885;


//shades of white 
$white-1: #FBFCFF;
$white-2: #D9E5FF;
$white: #FFFFFF;

//shades of grey 
$grey-1: #3E3E3E;
$grey-2: #4F4F4F;
$grey-3: #4E5A74;
$grey-4: #3E3E3E;
$grey-5: #D0D6E2;
$grey-6: #979797;
$grey-7: #707070;
$grey-8: #CED4DA;
$grey-9: #536488;
$grey-10: #333333;

//shades of blue
$blue-1: #A6C1FD;
$blue-2: #EBF6FF;
$blue-3: #F9FCFF;
$blue-4: #F6FBFF;
$blue-5: #255FDD;

$blue-8: #FBFCFF;
$blue-7: #536488;
$blue-6: #9CAED6;
$blue-7: #E1EFFF;
$blue-8: #EBEDFA;
$blue-9: #D9EBFF;
$blue-10: #FCFDFF;
$blue-11: #ECF2FF;
$blue-12: #D3E1FF;


//shades of black 
$black: #000;

//shades of red
$red: red;