/* Mixin for Flexbox Container */
@mixin flex-container($direction: row, $wrap: nowrap, $justify: flex-start, $align: stretch) {
    display: flex;
    flex-direction: $direction;
    flex-wrap: $wrap;
    justify-content: $justify;
    align-items: $align;
  }

  @mixin flex($direction:row, $justify: center, $align:center){
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
  }
  
  /* Mixin for Flexbox Item */
  @mixin flex-item($flex: 1, $order: 0, $align-self: auto) {
    flex: $flex;
    order: $order;
    align-self: $align-self;
  }

  @mixin custom-height-width($height:100%, $width:100%) {
    height: $height;
    width: $width;
  }
  
  /* Mixin for Centering Horizontally and Vertically */
  @mixin center-both {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @mixin horizontally-center {
    display: flex;
    justify-content: center;
  }

  
  @mixin vertically-center {
    display: flex;
    align-items: center;
  }
  
  
  /* Mixin for Ellipsis Overflow */
  @mixin text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Mixin for Responsive Font Size */
  @mixin responsive-font-size($min-font-size, $max-font-size, $min-screen-width, $max-screen-width) {
    font-size: $min-font-size;
  
    @media screen and (min-width: $min-screen-width) {
      font-size: calc(#{$min-font-size} + (#{$max-font-size} - #{$min-font-size}) * ((100vw - #{$min-screen-width}) / (#{$max-screen-width} - #{$min-screen-width})));
    }
  
    @media screen and (min-width: $max-screen-width) {
      font-size: $max-font-size;
    }
  }
  
  /* Add more mixins as per your project's needs */
   /* px to rem */
  @mixin toRem($property, $value) {
    #{$property}: calc($value / 16) + rem;
  } 
  /* rem to px */
  @mixin toPx($property, $value) {
    #{$property}: calc($value * 16) + px;
  } 

  $breakpoints: (
    small: 576px,
    medium: 768px,
    large: 992px,
    x-large: 1200px
  );
  
  @mixin media-query($breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }


  @mixin position-transform($pos:absolute, $top:50%, $left:50%){
    position: $pos;
    top: $top;
    left: $left;
    transform: translate(-$top, -$left);
  }

  @mixin font-color-weight-size($color, $weight, $size){
    color: $color;
    font-weight: $weight;
    font-size: $size;
  }
