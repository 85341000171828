/* You can add global styles to this file, and also import other style files */
@import "./styles/colors";
@import "./styles/common-mixins";
@import "primeicons/primeicons.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: $white-1;
}

.blue-text {
  color: $primary-color;
}

.grey-text {
  color: $secondary-color;
}

.card {
  border: 2px solid $blue-1;
  border-radius: 25px;
  background-color: $white;
}

.main-container {
  position: relative;
  top: 70px;
}

// global button properties
.btn {
  border-radius: 8px;
  height: 48px;
  font-weight: 500;
}

.btn-primary, .btn-lg {
  background-color: $primary-color;
  &:hover {
    background-color: $blue-5;
    box-shadow: 0px 1px 4px 0px #00000029 inset;
  }
}

.btn-large {
  padding-left: 144px !important;
  padding-right: 144px !important;
}

.btn-secondary {
  background-color: $white;
  color: $primary-color;
  border-color: $primary-color;

  &:hover {
    background-color: $blue-5;
    box-shadow: 0px 1px 4px 0px #00000029 inset;
  }
}
.btn-outline-primary{
  background-color: $white;
  color: $primary-color;
  border: 1px solid $primary-color;
    &:hover{
      background-color: $primary-color;
      border: 1px solid $primary-color;
      color: $white;
    }
}

.btn-disabled {
  background-color: $grey-5 !important;
  border: none;
  cursor: no-drop;
  pointer-events: none;
}

// global p-input properties
.input-text {
  background: $white;
  border-radius: 8px;
  caret-color: $primary-color;
  font-weight: 500;
  color: $grey-2;

  &::placeholder {
    font-weight: 300;
  }

  &:focus {
    border: 1px solid $primary-color;
    box-shadow: none !important;
  }
}

.p-float-label input:focus~label,
.p-float-label input.p-filled~label,
.p-float-label textarea:focus~label,
.p-float-label textarea.p-filled~label,
.p-float-label .p-inputwrapper-focus~label,
.p-float-label .p-inputwrapper-filled~label {
  left: 4px;
  color: $grey-2;
}

// global headers property 
.header {
  color: $grey-2;
  font-weight: 500;
}

.sub-header {
  color: $grey-7;
  font-weight: 400;
}
/* change css for sweet alert modal  */
 html * .swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen)>.swal2-modal{
  border-radius: 17px !important;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06) !important;
}

html * .swal2-styled.swal2-confirm{
 box-shadow: 0px 4px 10px rgba(44, 102, 227,0.06) !important;
 border-radius: 4px;
 color: $white;
 background-color: $primary-color;
}

html * .swal2-styled.swal2-cancel{
 border: 1px solid $secondary-color;
 box-sizing: border-box;
 border-radius: 4px;
 color: $secondary-color;
}



ngx-otp-input {
  width: 80%;
  margin: 0 auto;
  .my-new-special-class {
    background: $white !important;
    caret-color: $primary-color !important;
    font-weight: 500 !important;
    color: $grey-2 !important;
  }
}
ngx-otp-input {
  .ngx-otp-input-box.my-super-class {
    background: $white !important;
    caret-color: $primary-color !important;
    font-weight: 500 !important;
    color: $grey-2 !important;

    &::placeholder {
      font-weight: 300 !important;
    }

    &:focus {
      border: 1px solid $primary-color !important;
      box-shadow: none !important;
    }
  }
}
.cursor {
  cursor: pointer;
}
// simulate Angular CDK media queries
@media (max-width: 600px) and (orientation: portrait),
(max-width: 960px) and (orientation: landscape) {
  body {
    background-color: $white;
  }
}